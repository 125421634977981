define(['app'], function(app) {


  const youtube = function(element, options) {
    const obj = {};

    obj.config = {
      video: '', // id of youtube video
      className: 'simpleYoutube_player', // iframe class
      autoplay: false, // autoplay video
      forceCaptions: false, // force captions to appear
      color: 'red', // color of progress bar (options: red and white)
      controls: true, // show controls
      disableKeyboard: false, // disable keyboard controls
      startTime: 0, // time video starts
      endTime: -1, // time video ends
      disableFullscreen: false, // disable fullscreen button
      language: 'en', // language of the player, IETF format
      annotations: true, // show annotations
      loop: false, // loop video/playlist
      noLogo: false, // disable bottom right youtube logo
      showRelated: false, // show related videos
      mobileFullscreen: false, // player goes fullscreen on mobile
      showInfo: true, // show video info at the top
      replace: false // replace parent element
    };

    obj.types = {
      video: String,
      className: String,
      autoplay: Boolean,
      forceCaptions: Boolean,
      color: String,
      controls: Boolean,
      disableKeyboard: Boolean,
      startTime: Number,
      endTime: Number,
      disableFullscreen: Boolean,
      language: String,
      annotations: Boolean,
      loop: Boolean,
      noLogo: Boolean,
      showRelated: Boolean,
      mobileFullscreen: Boolean,
      showInfo: Boolean,
      replace: Boolean
    };

    obj.init = function(el) {
      obj.element = el;

      Object.keys(obj.config).forEach(function(key) {
        let attrib = obj.element.getAttribute('data-' + key);

        if (!attrib) {
          return;
        }

        switch (obj.types[key]) {
          case Boolean:
            obj.config[key] = attrib === 'true';
            break;
          case Number:
            obj.config[key] = Number(attrib) || obj.config[key];
            break;
          case String:
            obj.config[key] = attrib;
            break;
        }
      });

      obj.build();
    };

    obj.player = function(element, options) {
      if (typeof options === 'object') {
        obj.config = app.utils.extend(obj.config, options);
      }

      obj.element = element;
      return obj.build();
    };

    obj.build = function() {
      // Check element exists
      if (!obj.element) {
        return null;
      }

      // Check a video id was passed
      if (!obj.config.video) {
        return null;
      }

      // Check iframe exists already
      if (obj.iframe) {
        return obj.iframe;
      }

      obj.query = '';
      obj.query += obj.config.autoplay ? 'autoplay=1&' : '';
      obj.query += obj.config.forceCaptions ? 'cc_load_policy=1&' : '';
      obj.query += obj.config.color === 'white' ? 'color=white&' : '';
      obj.query += !obj.config.controls ? 'controls=0&' : '';
      obj.query += obj.config.disableKeyboard ? 'disablekb=1&' : '';
      obj.query += 'start=' + obj.config.startTime + '&';
      obj.query += obj.config.endTime > 0 ? 'end=' + obj.endTime + '&' : '';
      obj.query += obj.config.disableFullscreen ? 'fs=0&' : '';
      obj.query += 'hl=' + obj.config.language + '&';
      obj.query += !obj.config.annotations ? 'iv_load_policy=3&' : '';
      obj.query += obj.config.loop ? 'loop=1&' : '';
      obj.query += obj.config.noLogo ? 'modestbranding=1&' : '';
      obj.query += !obj.config.showRelated ? 'rel=0&' : '';
      obj.query += 'playsinline=' + (obj.config.mobileFullscreen ? '0' : '1') + '&';
      obj.query += !obj.config.showInfo ? 'showinfo=0&' : '';
      obj.query = obj.query.slice(0, obj.query.length - 1);

      obj.iframe = document.createElement('iframe');
      obj.iframe.src = 'https://www.youtube-nocookie.com/embed/' + obj.config.video + '/?' + obj.query;
      obj.iframe.className = obj.config.className;
      obj.iframe.setAttribute('type', 'text/html');
      obj.iframe.setAttribute('frameborder', '0');
      obj.iframe.setAttribute('allowfullscreen', '');

      if (obj.config.replace) {
        obj.element.parentNode.replaceChild(obj.iframe, obj.element);
        obj.element = obj.iframe;
      } else {
        obj.element.innerHTML = '';
        obj.element.appendChild(obj.iframe);
      }

      return obj.iframe;
    };

    if (!element || !(element instanceof HTMLElement)) {
      return obj;
    }

    return obj.player(element, options);
  };

  return youtube;
});
